<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row align-content="center" style="height: 100vh">
          <v-col cols="12">
            <v-row justify="center">
              <v-card elevation="3" max-width="450px" width="100%">
                <v-img
                  :src="require('@/assets/images/logos/devias.png')"
                  max-height="500px"
                  max-width="250px"
                  alt="logo"
                  contain
                  class="mx-auto"
                ></v-img>
                <v-card-title primary-title> Recuperar contraseña</v-card-title>
                <v-card-text>
                  <v-text-field
                    :type="show.password ? 'text' : 'password'"
                    :append-icon="show.password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show.password = !show.password"
                    placeholder="******"
                    label="Nueva contraseña"
                    prepend-inner-icon="mdi-key-variant"
                    outlined
                    color="primary"
                    dense
                    v-model="password"
                    v-on:keyup.enter="setPassword"
                  ></v-text-field>
                  <v-text-field
                    :type="show.password_confirmation ? 'text' : 'password'"
                    :append-icon="show.password_confirmation ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show.password_confirmation = !show.password_confirmation"
                    placeholder="******"
                    label="Confirmar contraseña"
                    prepend-inner-icon="mdi-key-variant"
                    outlined
                    color="primary"
                    dense
                    v-model="password_confirmation"
                    v-on:keyup.enter="setPassword"
                  ></v-text-field>
                  <v-alert type="error" elevation="1" color="error" class="mt-2" v-if="errores.length > 0">
                    <span v-for="(error, index) in errores" v-bind:key="index" class="d-block">
                      {{ error }}
                    </span>
                  </v-alert>
                  <v-alert type="info" elevation="1" color="info" v-if="message != ''">
                    <p>{{ message }}</p>
                  </v-alert>
                </v-card-text>
                <v-card-actions class="mb-4 mx-4">
                  <v-row dense justify="end">
                    <v-col cols="auto">
                      <v-btn color="primary" @click="setPassword" :loading="disableButton">Recuperar</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <div></div>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
export default {
  name: 'resetear-contrasena',
  props: {},
  data() {
    return {
      password: '',
      password_confirmation: '',
      message: '',
      errores: [],
      disableButton: false,
      show: {
        password: false,
        password_confirmation: false,
      },
      token: null,
    }
  },
  created() {
    this.token = this.$route.params.token

    let v = this
    this.$http.get(this.$base_api_sanctum_url + '/sanctum/csrf-cookie').then(() => {
      this.$http
        .post(v.$base_api_url + '/ask-for-token-reset', {
          token: this.token,
        })
        .then(() => {})
        .catch(res => {
          if (res.response) {
            if (res.response.status == 404) {
              this.$swal
                .fire({
                  text: `Error: al parecer este link ya caduco.`,
                  icon: `error`,
                  showClass: {
                    popup: v.isDark ? 'theme-dark' : '',
                  },
                })
                .then(() => {
                  this.$router.push({ name: 'login' })
                })
            } else {
              this.$swal
                .fire({
                  text: `Errro del servidor, intenta mas tarde.`,
                  icon: `error`,
                  showClass: {
                    popup: v.isDark ? 'theme-dark' : '',
                  },
                })
                .then(() => {
                  this.$router.push({ name: 'login' })
                })
            }
          } else {
            this.$swal
              .fire({
                text: `Errro inesperado, intenta mas tarde.`,
                icon: `error`,
                showClass: {
                  popup: v.isDark ? 'theme-dark' : '',
                },
              })
              .then(() => {
                this.$router.push({ name: 'login' })
              })
          }
        })
    })
  },
  methods: {
    setPassword() {
      this.disableButton = true
      this.message = ''
      this.errores = []
      let v = this
      let url = v.$base_api_url + '/recuperar-contrasena/' + v.token
      let data = {
        password: v.password,
        password_confirmation: v.password_confirmation,
      }

      axios
        .post(url, data)
        .then(() => {
          v.disableButton = true
          let s = 5
          setInterval(function() {
            if (s == 0) {
              v.$router.push({ name: 'login' })
            }
            v.message = 'Se restablecio correctamente la contraseña, Redirigiendo en ' + s + ' s'
            s--
          }, 1000)
        })
        .catch(res => {
          this.disableButton = false
          if (res.response == undefined) {
            v.errores.push('Error inesperado, intenta mas tarde')
            return null
          }
          if (res.response.status == 422) {
            v.errores = []
            let keys = Object.keys(res.response.data.errors)
            for (let i = 0; i < keys.length; i++) {
              const element = res.response.data.errors[keys[i]]
              element.forEach(e => {
                v.errores.push(e)
              })
            }
            return null
          }
          if (res.response.status == 429) {
            v.errores.push('Demaciados intentos, espera un minuto')
            return null
          }

          v.errores.push(`Error: ${res.response.status}, ${res.response.statusText}`)
        })
    },
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark
    },
  },
}
</script>
